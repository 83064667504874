@use 'common/styles/fonts';

.ModeBadge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 4px;
  border-radius: 9999px;
  white-space: nowrap;
  font-family: fonts.$font;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
