$breakpoint-mobile: 768px;
$breakpoint-tablet: 1024px;

@mixin mobile {
  @media screen and (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $breakpoint-mobile) {
    @content;
  }
}
