
.FallbackUI{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon{
        width: 170px;
        height: 170px;
    }
    .information{
        display: flex;
    }
    .title{
        margin-top:20px;
    }
    .button{
        width:200px;
        margin-top:20px;
    }
}

