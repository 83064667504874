@use 'common/styles/other';
@use 'common/styles/breakpoints';

.Portal {
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 100dvh;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 35px;
    min-width: 0;
    flex: 1 1 0px;

    @include breakpoints.mobile {
      margin-left: 0;
      padding: 20px;
      margin-bottom: other.$side-nav-width;
    }
  }
  .contentWithNav {
    margin-left: other.$side-nav-width;
    margin-top: other.$top-nav-height;

    @include breakpoints.mobile {
      margin-left: 0;
    }
  }
}
