@use "common/styles/colors.module";
@use "common/styles/animation";
@use "common/styles/other";
@use "common/styles/breakpoints";

.SideNavButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: other.$side-nav-width;
  width: other.$side-nav-width;
  padding: 4px;
  color: colors.$on-primary-medium;
  position: relative;
  transition: background-color animation.$transition;
  cursor: pointer;
  text-align: center;

  &.active {
    color: colors.$surface;
    background-color: colors.$navigation-variant;
  }

  @include breakpoints.mobile {
    width: 100%;
  }

  .text {
    font-size: 8px;
    @include breakpoints.mobile {
      font-size: 7px;
    }
  }

  .selectedBar {
    position: absolute;
    background-color: colors.$surface;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0px;
    &.selected {
      width: 2px;
      @include breakpoints.mobile {
        width: 100%;
        height: 2px;
      }
    }
    @include breakpoints.mobile {
      top: unset;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }

  svg {
    padding-bottom: 6px;
  }
}

.SubNav {
  display: none;
  position: absolute;
  left: 100%;
  bottom: 0px;
  min-width: 250px;
  flex-direction: column;
  border-radius: other.$border-radius;
  box-shadow: colors.$elevation-02;
  background-color: colors.$surface;
  z-index: 1;

  @include breakpoints.mobile {
    left: 50%;
    transform: translateX(-50%);
    bottom: other.$side-nav-width;
    top: initial;
  }

  &.open {
    display: flex;
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 18px;

    &.selected {
      background-color: colors.$overlay-surface-08;
      color: colors.$on-surface-high;
    }
    &:hover {
      background-color: colors.$overlay-surface-04;
    }
    &:active {
      background-color: colors.$overlay-surface-12;
    }

    .text {
      margin-left: 10px;
      color: colors.$on-surface-high;
      font-size: inherit;
    }

    svg {
      padding-bottom: 0;
    }
  }
}
