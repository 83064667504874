@use "common/styles/colors.module";
@use "common/styles/animation";
@use "common/styles/other";
@use "common/styles/breakpoints";

.SideNav {
  background-color: colors.$navigation;
  position: fixed;
  display: flex;
  box-shadow: colors.$elevation-03;
  z-index: 6;
}

.SideNavDesktop {
  width: other.$side-nav-width;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoints.mobile {
    display: none;
  }

  .menu {
    display: flex;
    flex-direction: column;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: other.$side-nav-width;
  }

  .footer {
    margin-bottom: 20px;
  }
}

.SideNavMobile {
  z-index: 4;
  bottom: 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  @include breakpoints.desktop {
    display: none;
  }
}
