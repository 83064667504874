@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scroll-bar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
