// Container
@use "common/styles/colors.module";
@use "common/styles/other";

.Container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-height: 0;
  padding-top: 25px;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }
}

// Header

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
}

// Text buttons container

.TextButtonsContainer {
  display: flex;
  gap: 25px;
}

// Operation table

.TableHead {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.TableHead > .TableRow:first-child {
  border-top-right-radius: other.$border-radius;
  border-top-left-radius: other.$border-radius;
}

.TableBody > .TableRow:last-child {
  border-bottom-right-radius: other.$border-radius;
  border-bottom-left-radius: other.$border-radius;
}

.TableWrapper {
  flex: 1 1 0;
  min-height: 0;
}

.TableScroll {
  max-height: 100%;
  width: 100%;
  overflow: auto;
  overscroll-behavior: none;
}

.TableContainer {
  min-width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.TableNoData {
  flex: 1 1 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .Info {
    text-align: center;
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

.TableRow {
  --table-h-padding: 11px;
  display: flex;
  background-color: white;
  min-height: 45px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: var(--table-h-padding);
  padding-right: var(--table-h-padding);
  border-bottom: colors.$outline;

  &Selected {
    background-color: colors.$primary-050;
  }
}

// Priority cell

.PriorityContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  .PriorityIcon {
    width: 16px;
    height: 16px;
  }
}

// Loading screen
.LoadingIndicatorContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .LoadingIndicator {
    color: colors.$primary-500 !important;
  }
}

// Side nav
.NewOperationFeatureButton {
  background-color: colors.$primary-500;
  svg {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
  }
  &Active {
    svg {
      flex-shrink: 0;
      width: 25px;
      height: 25px;
    }
  }
}
