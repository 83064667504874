@use 'common/styles/fonts';

// Line height not included

.Headline_1 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 300;
  font-size: 96px;
  letter-spacing: -1.5px;
}

.Headline_2 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  letter-spacing: -0.5px;
}

.Headline_3 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
}

.Headline_4 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
}

.Headline_5 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.18px;
}

.Headline_6 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.15px;
}

.Subtitle_1 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
}

.Subtitle_2 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.Body_1 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
}

.Body_2 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.Body_3 {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.Button {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.Caption {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.Overline {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.Emphasized {
  font-family: fonts.$font;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
