@use "common/styles/breakpoints";

.ErrorOverviews {
  background-color: #8688c5;
  border-radius: 8px;

  .HeadingAndButtonContainer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    padding: 8px 16px;
    line-height: 24px;
  }

  .ToggleButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
  }

  .ErrorOverviewContainer {
    height: 108px;
    box-sizing: border-box;
    padding-bottom: 12px;

    .ErrorOverviewCardsAndFrequentErrorContainer {
      display: flex;
      gap: 16px;
      height: 100%;
      padding-right: 16px;

      .ErrorOverviewCardScrollerFrequentErrorCardContainer {
        flex-shrink: 0;
        display: none;
        scroll-snap-align: start;

        @media (max-width: breakpoints.$breakpoint-mobile) {
          display: block;
        }
      }
    }

    .FrequentErrorCardContainer {
      height: 100%;
      flex-shrink: 0;

      @media (max-width: breakpoints.$breakpoint-mobile) {
        display: none;
      }
    }
  }

  .ErrorOverviewsCardScroller {
    display: flex;
    padding-left: 16px;
    scroll-padding: 16px;
    scroll-snap-type: x mandatory;
    overflow: auto;
    gap: 16px;
    box-sizing: border-box;
    overscroll-behavior: contain;
    scrollbar-width: none;

    &::webkit-scrollbar {
      display: none;
    }
  }
}

.Card {
  background-color: white;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
  scroll-snap-align: start;
  width: 120px;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: breakpoints.$breakpoint-mobile) {
    width: 160px;
  }

  .TitleText {
    line-height: 16px;
    text-wrap: balance;
  }

  .BottomHalfContainer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .LabelContainer {
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.FadeInMoveUp {
  animation: FadeInMoveUp 300ms ease-out;
}

@keyframes FadeInMoveUp {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.FrequentErrorCard {
  border-radius: 8px;
  border: 1px solid #f89389;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  width: 160px;
  height: 100%;
  cursor: pointer;
  outline: none;
  padding: 0;
  align-items: stretch;
  transition: background-color 0.1s ease-out;

  &:hover {
    background-color: hsl(12, 71%, 97%);
  }

  @media (min-width: breakpoints.$breakpoint-mobile) {
    width: 192px;
  }

  .FrequentErrorHeader {
    background-color: #e95d50;
    padding: 2px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .NoErrorWrapper {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .TextWrapper {
      max-width: 80%;
      line-height: 16px;
      text-align: center;
      text-wrap: balance;
    }
  }

  .FrequentErrorBody {
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    line-height: 16px;

    .FrequentRow {
      display: flex;
      justify-content: space-between;
      padding-bottom: 2px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      @media (min-width: breakpoints.$breakpoint-mobile) {
        padding-right: 36px;
      }
      .ErrorText {
        overflow: hidden;
        padding-right: 10px;

        > * {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .CountText {
        flex-shrink: 0;

        > * {
          display: block;
        }
      }
    }
  }
}
