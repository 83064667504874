@use "common/styles/breakpoints";

.ErrorBoardContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1 1 0;

  .ErrorBoard {
    padding: 8px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    flex: 1 1 0;

    @media (min-width: breakpoints.$breakpoint-mobile) {
      padding: 16px;
    }

    .SubNavAndTableContainer {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      flex-direction: column;
      gap: 2px;
      flex: 1 1 0;
    }
  }
}
