@use "common/styles/colors.module";
@use "common/styles/fonts";

$focused-color: colors.$primary-400;

/* Drop down variables */
$dropdown-selected-color: colors.$primary-050;
$dropdown-selected-hover-color: colors.$primary-050;
$dropdown-selected-focus-color: colors.$primary-050;

$dropdown-hover-color: rgb(235, 235, 235);
$dropdown-focus-color: rgb(235, 235, 235);

.InputLabel {
  font-family: fonts.$font !important;
  color: colors.$on-surface-medium;

  &:global(.Mui-focused) {
    color: $focused-color !important;
  }

  &Required {
    font-family: fonts.$font !important;
    color: colors.$on-surface-medium;

    &:global(.Mui-focused) {
      color: $focused-color !important;
    }

    &::after {
      content: "*";
      color: colors.$error;
      font-family: fonts.$font;
    }
  }
}

.Select {
  :global(.MuiInputBase-input) {
    font-family: fonts.$font;

    .Label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:global(.Mui-focused) {
    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: $focused-color !important;
    }
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border: colors.$outline;
  }
}

.MenuItem {
  font-family: fonts.$font !important;

  &:global(.Mui-selected) {
    background-color: $dropdown-selected-color !important;
    &:global(.Mui-focusVisible) {
      background-color: $dropdown-selected-focus-color !important;
    }
    &:hover {
      background-color: $dropdown-selected-hover-color !important;
    }
  }
  &:global(.Mui-focusVisible) {
    background-color: $dropdown-focus-color !important;
  }
  &:hover {
    background-color: $dropdown-hover-color !important;
  }
}

.ItemContainer {
  display: flex;
  align-items: center;

  .Icon {
    flex-shrink: 0;
    min-width: 0;
    flex-basis: 16px;
    width: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 5px;
  }
  .Label {
    white-space: normal;
  }
}

.MenuDropdown {
  max-width: min(400px, calc(100% - 32px)) !important;
  max-height: min(200px, calc(100% - 36px)) !important;
}
