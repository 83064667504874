.NoData {
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  position: sticky;
  left: 0;

  .TextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}

.FadeInMoveUp {
  animation: FadeInMoveUp 300ms ease-out;
}

@keyframes FadeInMoveUp {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
