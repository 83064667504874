.RefreshButton {
  padding: 16px;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes Spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Spin {
  animation: Spin 1s ease-in-out infinite;
}

