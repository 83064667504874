@use "common/styles/colors.module";
@use "common/styles/other";
@use "common/styles/breakpoints";

.TopNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: colors.$surface;
  margin-left: other.$side-nav-width;
  height: other.$top-nav-height;
  border-bottom: colors.$outline;
  padding: 10px;
  z-index: 6;
  > * {
    border-left: 1px colors.$disabled solid;
  }

  @include breakpoints.mobile {
    margin-left: 0;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    position: relative;

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .avatar {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-left: 10px;
    background-color: colors.$background;
    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > .organisation {
    display: flex;
    align-items: center;
    min-width: 180px;
    height: 60px;
    position: relative;
    .organisationPicture {
      max-width: 40px;
      max-height: 40px;
      border-radius: 40px;
      background-color: colors.$background;
      margin-right: 10px;
      margin-left: 10px;
      .image {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        object-fit: cover;
      }
    }
  }
  .user {
    display: flex;
    align-items: center;
    height: 60px;
  }
}

.select {
  max-width: 150px;
}

.orgName {
  min-width: 150px;
}

.ViewAllContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 60px;
  padding: 0 12px;
  position: relative;
  .toggleButtonText {
    margin-left: 8px;
  }
}
