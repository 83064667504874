
.FadeIn {
  animation: FadeIn 200ms ease-in-out;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.FadeInMoveUp {
  animation: FadeInMoveUp 300ms ease-out;
}

@keyframes FadeInMoveUp {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Fallback {
  background-color: #ffd8d4;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


