@use "common/styles/colors.module";
@use "common/styles/other";
@use "common/styles/fonts";
@use "common/styles/animation";

.label {
  color: colors.$on-surface-medium;
  height: 18px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 16px;
  background-color: colors.$surface;
  transition:
    top animation.$transition,
    opacity animation.$transition;
  opacity: 0;
  &.active {
    top: -9px;
    padding: 0 5px;
    opacity: 1;
  }
  &.line {
    left: 0;
    padding: 0;
  }
  &.none {
    left: 0;
    padding: 0;
  }
}

.unit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 2px;
  display: flex;
  align-items: center;
  background-color: colors.$surface;
}

.field {
  box-sizing: border-box;
  width: 100%;
  background-color: colors.$surface;
  margin-bottom: 0;
  transition: all animation.$transition;
  caret-color: colors.$primary;
  border-radius: other.$border-radius;
  font-family: fonts.$font-regular;
  &::placeholder {
    color: colors.$on-surface-disabled;
  }

  &.error {
    border: 1px solid colors.$error !important;
  }
}

.box {
  padding: 16px;
  border: colors.$outline;
  &:focus {
    border: 1px solid colors.$primary;
  }
}

.line {
  padding: 8px 0;
  border: none;
  outline: none;
  border-radius: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  &:focus {
    border-bottom: 2px solid colors.$primary;
  }
}

.none {
  padding: 8px 0;
  border: none;
  outline: none;
  border-radius: 0;
}

.noLineBox {
  padding: 16px;
  border: none;
  &:focus {
    border: 1px solid colors.$primary;
  }
}

.errorMessage {
  color: colors.$error;
  position: absolute;
  bottom: -20px;
  white-space: nowrap;
}

.icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.ErrorText {
  color: colors.$error !important;
}
