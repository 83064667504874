.TableContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-snap-type: both mandatory;
  display: flex;
  flex-direction: column;
  scroll-padding-top: 40px;

  .MinWidthContainer {
    min-width: min-content;
  }

  .HeaderRow {
    background-color: #f5f5f5;
    display: flex;
    border-bottom: 1px solid rgba(102, 102, 102, 0.32);
    position: sticky;
    top: 0;
    z-index: 2;
    height: 40px;
  }

  .Row {
    display: flex;
    border-bottom: 1px solid rgba(102, 102, 102, 0.32);
    min-height: 48px;

    .LoadingCell {
      display: flex;
      align-items: center;

      .SkeletonContainer {
        width: 100%;
        padding: 3px;
      }
    }
  }
}


.FadeIn {
  animation: FadeIn 200ms ease-in-out;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

