@use 'common/styles/fonts';

.Typography {
  white-space: pre-line;

  &.headline-1 {
    font-family: fonts.$font-light;
    font-size: 96px;
  }
  &.headline-2 {
    font-family: fonts.$font-light;
    font-size: 60px;
  }
  &.headline-3 {
    font-family: fonts.$font-regular;
    font-size: 48px;
  }
  &.headline-4 {
    font-family: fonts.$font-regular;
    font-size: 34px;
  }
  &.headline-5 {
    font-family: fonts.$font-regular;
    font-size: 24px;
  }
  &.headline-6 {
    font-family: fonts.$font-medium;
    font-size: 20px;
  }
  &.subtitle-1 {
    font-family: fonts.$font-regular;
    font-size: 16px;
  }
  &.subtitle-2 {
    font-family: fonts.$font-medium;
    font-size: 14px;
  }
  &.body-1 {
    font-family: fonts.$font-regular;
    font-size: 16px;
  }
  &.body-2 {
    font-family: fonts.$font-regular;
    font-size: 14px;
  }
  &.button {
    font-family: fonts.$font-medium;
    font-size: 14px;
    text-transform: uppercase;
  }
  &.caption {
    font-family: fonts.$font-regular;
    font-size: 12px;
  }
  &.overline {
    font-family: fonts.$font-regular;
    font-size: 10px;
    text-transform: uppercase;
  }
}
