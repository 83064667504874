@use "common/styles/colors.module";
@use "common/styles/other";
@use "common/styles/breakpoints";

.Login {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: colors.$navigation;

  .content {
    z-index: 1;
    margin: 60px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    @include breakpoints.mobile {
      margin-top: 20px;
    }

    .logoWrapper {
      width: 311px;
      margin-bottom: 32px;
      @include breakpoints.mobile {
        width: 150px;
        margin-bottom: 20px;
      }
      .logo {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      max-width: 450px;
      width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
      background-color: colors.$surface;
      border-radius: other.$border-radius;
      padding: 40px;
      margin: 0 20px;
      box-sizing: border-box;
      box-shadow: colors.$elevation-04;
      @include breakpoints.mobile {
        padding: 20px;
      }
      .title {
        margin-bottom: 20px;
      }
      .orLine {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .or {
          margin: 0 15px;
          color: colors.$on-surface-disabled;
        }
        .line {
          border-bottom: colors.$outline;
          flex: 1;
        }
      }
      .googleLogin {
        margin-bottom: 20px;
      }
      .input {
        margin-bottom: 20px;
      }
      .errorMessage {
        color: colors.$error;
      }
      .signInButton {
        margin-top: 5px;
        margin-bottom: 10px;
      }
      .prompt {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        .link {
          color: colors.$primary;
          cursor: pointer;
        }
      }
    }
  }

  .vendingcity {
    position: fixed;
    object-fit: contain;
    bottom: 0;
    width: 100%;
    @include breakpoints.tablet {
      width: initial;
      left: 0;
      height: 90%;
    }
    @include breakpoints.mobile {
      height: 50%;
    }
  }
}

.Warn {
  color: #ff0000;
}
