/* reset border default style */
*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme("borderColor.DEFAULT", currentColor);
}

/* Reset typographic elements */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
ul,
ol,
li,
blockquote,
figure,
figcaption,
dl,
dt,
dd {
  margin: 0;
}
