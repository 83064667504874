@use "common/styles/colors.module";
@use "common/styles/fonts";

$focused-color: colors.$primary-400;

.TextField {
  :global(.MuiInputBase-input) {
    font-family: fonts.$font;
  }

  &:global(.Mui-focused) {
    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: $focused-color !important;
    }
  }

  &:global(.MuiInput-underline):after {
    border-bottom-color: $focused-color,
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border: colors.$outline;
  }
}

.Label {
  font-family: fonts.$font !important;
  color: colors.$on-surface-medium;

  &:global(.Mui-focused) {
    color: $focused-color !important;
  }

  &Required {
    font-family: fonts.$font !important;
    color: colors.$on-surface-medium;

    &:global(.Mui-focused) {
      color: $focused-color !important;
    }

    &::after {
      content: "*";
      color: colors.$error;
      font-family: fonts.$font;
    }
  }
}
