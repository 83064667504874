
@use 'common/styles/breakpoints';
@use 'common/styles/fonts';

.ScrollContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.ErrorBoardTableTabsContainer {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  overscroll-behavior: contain;
}

.ErrorBoardTableTabsContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ScrollButton {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  outline: none;
  user-select: none;
}

.ScrollIcon {
  flex-shrink: 0;
  padding-left: 8px;
  padding-right: 8px;
}
