@use "common/styles/colors.module";

.Splash {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: colors.$navigation;

  .logoWrapper {
    width: 100px;
    margin-bottom: 40px;
    .logo {
      width: 100%;
      height: auto;
    }
  }
}
