// Nick's Font
@font-face {
  font-family: "font-main-light";
  src: url("~fonts/Kanit-Light.ttf");
}
@font-face {
  font-family: "font-main-regular";
  src: url("~fonts/Kanit-Regular.ttf");
}
@font-face {
  font-family: "font-main-medium";
  src: url("~fonts/Kanit-Medium.ttf");
}
@font-face {
  font-family: "font-alt-light";
  src: url("~fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "font-alt-regular";
  src: url("~fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "font-alt-medium";
  src: url("~fonts/Roboto-Medium.ttf");
}

$font-regular: font-main-regular, font-alt-regular, Arial, Helvetica, sans-serif;
$font-medium: font-main-medium, font-alt-medium, Arial, Helvetica, sans-serif;
$font-light: font-main-light, font-alt-light, Arial, Helvetica, sans-serif;

// // Custom fonts
@font-face {
  font-family: "Kanit";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("~fonts/Kanit-Light.ttf");
}
@font-face {
  font-family: "Kanit";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("~fonts/Kanit-Regular.ttf");
}
@font-face {
  font-family: "Kanit";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("~fonts/Kanit-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("~fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("~fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("~fonts/Roboto-Medium.ttf");
}

$font: "Kanit", "Roboto", Arial, Helvetica, sans-serif;
