@use "common/styles/colors.module";

$disabled-off-bar: #dadada;
$disabled-on-bar: #cfd5f0;
$disabled-off-circle: #acacac;
$disabled-on-circle: #babbd9;

.ToggleSwitch {
  box-sizing: border-box;
  width: 34px;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  & * {
    box-sizing: border-box;
  }
}

.ToggleSwitchDisabled {
  cursor: revert;
}

.Bar {
  width: 100%;
  height: 14px;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
}
.BarOnDisabled {
  justify-content: flex-end;
  border-color: transparent;
  background-color: $disabled-on-bar;
}

.BarOffDisabled {
  justify-content: flex-start;
  border-color: transparent;
  background-color: $disabled-off-bar;
}

.BarOn {
  justify-content: flex-end;
  border-color: transparent;
  background-color: colors.$primary-200;
}

.BarOff {
  justify-content: flex-start;
  border-color: colors.$primary-200;
  background-color: transparent;
}

.Circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 2px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14);
}
.CircleOnDisabled {
  background-color: $disabled-on-circle;
}
.CircleOffDisabled {
  background-color: $disabled-off-circle;
}
.CircleOn {
  background-color: colors.$primary-500;
}
.CircleOff {
  background-color: colors.$primary-500;
}
