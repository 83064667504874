@use "common/styles/colors.module";
@use "common/styles/other";
@use "common/styles/animation";

.Button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 10px;
  border-radius: other.$border-radius;
  transition: all animation.$transition;
  box-sizing: border-box;
  user-select: none;

  &.primary {
    background-color: colors.$primary;
    color: colors.$on-primary-high;
    &:hover {
      background: colors.$overlay-primary-04;
    }
    &:active {
      background: colors.$overlay-primary-12;
    }

    &.loading {
      pointer-events: none;
      background: colors.$overlay-primary-12;
    }
  }

  &.secondary {
    background-color: colors.$surface;
    color: colors.$primary;
    border: colors.$outline;
    &:hover {
      background: colors.$overlay-surface-04;
    }
    &:active {
      background: colors.$overlay-surface-12;
    }

    &.loading {
      pointer-events: none;
      background: colors.$overlay-primary-12;
    }
  }

  &.status {
    background-color: colors.$error;
    color: colors.$on-primary-high;

    &:hover {
      background: hsl(4, 75%, 63%);
    }
    &:active {
      background: hsl(4, 71%, 68%);
    }

    &.loading {
      pointer-events: none;
      background: hsl(5, 71%, 68%);
    }
  }

  &.disabled {
    background: colors.$disabled;
    color: colors.$on-surface-disabled;
    pointer-events: none;
  }

  .preIcon {
    margin-right: 5px;
  }
  .postIcon {
    margin-left: 5px;
  }
  .onlyIcon {
    margin: 0;
  }
}
