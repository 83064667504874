.rdp-root {
  --rdp-accent-color: #4b4d9b;
  --rdp-accent-background-color: #8191d9;
  --rdp-range_start-background: #4b4d9b;
  --rdp-range_end-background: #4b4d9b;
  --rdp-day_button-border-radius: 4px;
  --rdp-day_button-border: 1px;
  --rdp-range_middle-color: #ffffff;
  --rdp-selected-border: 0px solid var(--rdp-accent-color);
}
