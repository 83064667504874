$primary: #4b4d9b;
$primary-variant: #292b56;
$primary-900: #080911;
$primary-800: #191a34;
$primary-700: #292b56;
$primary-600: #3a3c79;
$primary-500: #4b4d9b;
$primary-400: #6466b4;
$primary-300: #8688c5;
$primary-200: #8191d9;
$primary-100: #cbcce6;
$primary-050: #eeeef7;

$secondary: #b8e7e3;
$secondary-variant: #4cbdb7;
$secondary-900: #276865;
$secondary-800: #328682;
$secondary-700: #3da49f;
$secondary-600: #4cbdb7;
$secondary-500: #79cdc9;
$secondary-400: #88d3cf;
$secondary-300: #a6dedb;
$secondary-200: #b8e7e3;
$secondary-100: #d1efed;
$secondary-050: #e1f4f3;
$warning-background-color: #f8cecb;

$brainstorming-black-tint-300: #b3b3b3;

$navigation: #2c2e78;
$navigation-variant: #4e53b9;

$background: #f3f3fd;
$surface: #ffffff;
$error: #e95d50;
$error2: #f89389;
$critical: #e95d50;
$success: #27b6ba;
$success-overlay: #b2d3d3;
$warning: #ffb025;
$warning-overlay: #ffedcc;
$error-200: #ffdedb;
$investigate: #d36b0b;
$caption-disabled: #b0b0b0;

$chart-indigo: #33568c;
$chart-cornflower-blue: #698cc2;
$chart-light-blue: #b5c7d5;
$chart-peanut-brown: #f5dcb3;
$chart-coffee-brown: #a38d80;
$chart-pink: #f18989;

$on-primary-high: rgba(255, 255, 255, 1);
$on-primary-medium: rgba(255, 255, 255, 0.74);
$on-primary-disabled: rgba(255, 255, 255, 0.38);

$on-surface-high: rgba(0, 0, 0, 0.87);
$on-surface-medium: rgba(0, 0, 0, 0.6);
$on-surface-disabled: rgba(0, 0, 0, 0.38);

$on-secondary: #1c1c1c;
$on-background: #1c1c1c;
$on-error: #ffffff;

$outline: 1px solid rgba(0, 0, 0, 0.12);
$outline-color: rgba(0, 0, 0, 0.12);

$elevation-01: 0px 1px 4px rgba(0, 0, 0, 0.13);
$elevation-02: 0px 2px 5px 1px rgba(0, 0, 0, 0.16);
$elevation-03: 0px 3px 14px rgba(0, 0, 0, 0.2);
$elevation-04:
  0px 4px 10px rgba(0, 0, 0, 0.08),
  0px 1px 12px rgba(0, 0, 0, 0.1),
  0px 2px 8px rgba(0, 0, 0, 0.14);
$elevation-06:
  0px 6px 10px rgba(0, 0, 0, 0.14),
  0px 1px 18px rgba(0, 0, 0, 0.12),
  0px 3px 5px rgba(0, 0, 0, 0.2);
$elevation-08:
  0px 8px 10px rgba(0, 0, 0, 0.14),
  0px 3px 14px rgba(0, 0, 0, 0.12),
  0px 5px 5px rgba(0, 0, 0, 0.2);
$elevation-09:
  0px 9px 12px rgba(0, 0, 0, 0.14),
  0px 3px 16px rgba(0, 0, 0, 0.12),
  0px 5px 6px rgba(0, 0, 0, 0.2);
$elevation-12:
  0px 12px 17px rgba(0, 0, 0, 0.14),
  0px 5px 22px rgba(0, 0, 0, 0.12),
  0px 7px 8px rgba(0, 0, 0, 0.2);
$elevation-16:
  0px 16px 24px rgba(0, 0, 0, 0.14),
  0px 6px 30px rgba(0, 0, 0, 0.12),
  0px 8px 10px rgba(0, 0, 0, 0.2);
$elevation-24:
  0px 24px 38px rgba(0, 0, 0, 0.14),
  0px 9px 46px rgba(0, 0, 0, 0.12),
  0px 11px 15px rgba(0, 0, 0, 0.2);

$overlay-surface-04: #f5f5f5;
$overlay-surface-08: #ebebeb;
$overlay-surface-12: #e0e0e0;

$overlay-primary-04: #52549f;
$overlay-primary-08: #595ba3;
$overlay-primary-12: #6163a7;

$disabled: #d7d7d7;

:export {
  primary: $primary;
  primaryVariant: $primary-variant;
  brainStormingBlackTint300: $brainstorming-black-tint-300;
  primary900: $primary-900;
  primary800: $primary-800;
  primary700: $primary-700;
  primary600: $primary-600;
  primary500: $primary-500;
  primary400: $primary-400;
  primary300: $primary-300;
  primary200: $primary-200;
  primary100: $primary-100;
  primary050: $primary-050;
  secondary: $secondary;
  secondary700: $secondary-700;
  secondaryVariant: $secondary-variant;
  surface: $surface;
  background: $background;
  outline: $outline;
  outlineColor: $outline-color;
  error: $error;
  error2: $error2;
  critical: $critical;
  success: $success;
  successOverlay: $success-overlay;
  warning: $warning;
  warningOverlay: $warning-overlay;
  investigate: $investigate;
  disabled: $disabled;
  onPrimaryHigh: $on-primary-high;
  onPrimaryMedium: $on-primary-medium;
  onSurfaceHigh: $on-surface-high;
  onSurfaceMedium: $on-surface-medium;
  onSurfaceDisabled: $on-surface-disabled;
  onBackground: $on-background;
  chartIndigo: $chart-indigo;
  chartCornflowerBlue: $chart-cornflower-blue;
  chartLightBlue: $chart-light-blue;
  chartPeanutBrown: $chart-peanut-brown;
  chartCoffeeBrown: $chart-coffee-brown;
  chartPink: $chart-pink;
  captionDisable: $caption-disabled;
}
