@use 'common/styles/fonts';

.MachineStatusBadge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 24px;
  border-radius: 9999px;
  font-family: fonts.$font;
  font-size: 12px;
  line-height: 16px;
}
