@use "common/styles/colors.module";

.Disabled {
  background-color: colors.$disabled !important;
  padding: 10px 14px !important;

  .Label {
    color: colors.$on-surface-disabled !important;
  }
}

.Primary {
  background-color: colors.$primary-500 !important;
  padding: 10px 14px !important;
  color: colors.$primary-600 !important;
  position: relative !important;

  .Label {
    color: colors.$on-primary-high !important;

    &Loading {
      visibility: hidden;
    }
  }

  .LoadingIcon {
    color: colors.$on-primary-high !important;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }

  &:hover {
    background-color: colors.$primary-600 !important;
  }
}

.Secondary {
  background-color: transparent !important;
  color: colors.$primary-500 !important;
  padding: 9px 13px !important;
  border: colors.$outline !important;
  position: relative !important;

  .Label {
    color: colors.$primary-500 !important;

    &Loading {
      visibility: hidden;
    }
  }

  .LoadingIcon {
    color: colors.$primary-500 !important;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }

  &:hover {
    background-color: colors.$primary-050 !important;
  }
}

.Status {
  background-color: colors.$error !important;
  color: #d43627 !important;
  padding: 10px 14px !important;
  position: relative !important;

  .Label {
    color: colors.$on-primary-high !important;

    &Loading {
      visibility: hidden;
    }
  }

  .LoadingIcon {
    color: colors.$on-primary-high !important;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }

  &:hover {
    background-color: #d43627 !important;
  }
}

.Icon {
  margin-right: 6px;

  &Loading {
    visibility: hidden;
  }
}

.small {
  height: 40px !important;
  font-size: 0.875rem !important;

  .Icon {
    margin-right: 4px !important;
  }

  .LoadingIcon {
    width: 20px !important;
    height: 20px !important;
    margin-left: -10px !important;
    margin-top: -10px !important;
  }
}

.medium {
  height: 44px !important;
  font-size: 1rem !important;

  .Icon {
    margin-right: 6px !important;
  }

  .LoadingIcon {
    width: 24px !important;
    height: 24px !important;
    margin-left: -12px !important;
    margin-top: -12px !important;
  }
}

.large {
  height: 50px !important;
  font-size: 1.125rem !important;

  .Icon {
    margin-right: 8px !important;
  }

  .LoadingIcon {
    width: 28px !important;
    height: 28px !important;
    margin-left: -14px !important;
    margin-top: -14px !important;
  }
}
