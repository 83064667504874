@use 'common/styles/breakpoints';
@use 'common/styles/fonts';

.ErrorBoardNav {
  display: flex;
  border: 1.2px solid #2c2e78;
  background-color: #2c2e78;
  overflow: hidden;
  gap: 1.2px;
  border-radius: 4px;
  user-select: none;

  @media (min-width: 740px) {
    display: inline-flex;
  }
}

.NavLink {
  box-sizing: border-box;
  flex: 1 1 0;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 48px;
  position: relative;
  font-size: 14px;
  font-family: fonts.$font;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: center;
  transition: 100ms all ease-in-out;


  @media (min-width: breakpoints.$breakpoint-mobile) {
    min-width: 160px;
    height: 40px;
  }

  .Amount {
    font-family: fonts.$font;
    height: 16px;
    width: 16px;
    border-radius: 40px;
    background-color: #e95d50;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 8px;
    letter-spacing: 0.4px;
    position: absolute;
    top: 4px;
    right: 4px;
    animation: ExpandFadeIn 300ms ease-in-out;

    @media (min-width: breakpoints.$breakpoint-mobile) {
      position: static;
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
}

@keyframes ExpandFadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
