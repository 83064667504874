

@use 'common/styles/breakpoints';
@use 'common/styles/fonts';

.TabButton {
  scroll-snap-align: start;
  font-family: fonts.$font;
  padding: 12px 8px 8px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  user-select: none;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  white-space: nowrap;
  touch-action: manipulation;

  @media (min-width: breakpoints.$breakpoint-mobile) {
    padding: 8px;
  }

  .ChildTextContainer {
    display: inline-grid;

    .OverlaidText {
      font-weight: 500;
      grid-column-start: 1;
      grid-row-start: 1;
      visibility: hidden;
    }

    .DisplayText {
      grid-column-start: 1;
      grid-row-start: 1;
    }
  }

  .HighlightedUnderline {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1.5px;
    background-color: #4b4d9b;
    position: absolute;
    animation: HorizontalExpand 300ms ease-in-out;
    transform-origin: center center;
  }
  .Amount {
    position: absolute;
    font-family: fonts.$font;
    top: 4px;
    right: 0;
    height: 16px;
    border-radius: 40px;
    color: white;
    font-size: 8px;
    letter-spacing: 0.4px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e95d50;
    animation: ExpandFadeIn 300ms ease-in-out;

    @media (min-width: breakpoints.$breakpoint-mobile) {
      position: static;
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
}

@keyframes HorizontalExpand {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes ExpandFadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}